import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Chrome, Mail } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { loginUser } from '../../services/auth';
import './SignIn.css';
import AnimatedBackground from '../LandingPage/AnimatedBackground';

const SignInComponent = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { saveTokens, authToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      navigate('/home');
    }
  }, [authToken, navigate]);

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(data.email, data.password);
      await saveTokens(response.access_token, response.refresh_token);
      navigate('/home');
    } catch (error) {
      console.error('Error during login:', error);
      alert('Failed to login');
    }
  };

  if (authToken) return null;

  return (
    <div className="signin">
      <AnimatedBackground />
      <header className="header">
        <a className="logo-landing" href="/">Inveris</a>
        {/* <nav className="nav-menu">
          <a href="/product" className="nav-item dropdown">Product</a>
          <a href="/use-case" className="nav-item dropdown">Use Case</a>
          <a href="/wall-of-love" className="nav-item">Wall of Love</a>
          <a href="/resources" className="nav-item dropdown">Resources</a>
          <a href="/pricing" className="nav-item">Pricing</a>
        </nav>
        <div className="auth-buttons">
          <a href="/login" className="login-button">Login</a>
          <a href="/signup" className="free-trial-button">Free Trial</a>
        </div> */}
      </header>
      <main className="signin__main">
        <div className="signin__container">
          <h1 className="signin__title">Welcome to Inveris</h1>
          <p className="signin__subtitle">Connect with your work email and calendar</p>
          
          <div className="signin__oauth-buttons">
            <button className="signin__oauth-button signin__oauth-button--google">
              <Chrome size={20} />
              Continue with Google
            </button>
            <button className="signin__oauth-button signin__oauth-button--microsoft">
              <Mail size={20} />
              Continue with Outlook
            </button>
          </div>

          <div className="signin__divider">
            <span className="signin__divider-text">OR</span>
          </div>

          <form className="signin__form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              placeholder="Work email"
              className={`signin__input ${errors.email ? 'error' : ''}`}
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}

            <input
              type="password"
              placeholder="Password"
              className={`signin__input ${errors.password ? 'error' : ''}`}
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}

            <button type="submit" className="signin__submit">Sign in</button>
          </form>

          <div className="signin__account-prompt">
  Don't have an account? <a onClick={() => navigate('/signup')} className="signin__signup-link">Sign up</a>
</div>
          

          <div className="signin__links">
            <a href="/terms" className="signin__link">Terms of Service</a>
            <span className="signin__link-separator">•</span>
            <a href="/privacy" className="signin__link">Privacy Policy</a>
          </div>

          <div className="signin__trial-note">
            14-day free trial. No credit card required.
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignInComponent;