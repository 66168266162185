import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignupComponent from './SignUp';
import CompanyInfoPage from './CompanyInfo';
import { signupUser, signupUserWithInviteToken } from '../../services/signup';
import { handleGoogleCallback } from '../../services/auth';
import { companyCreate } from '../../services/company';
import { useAuth } from '../../hooks/useAuth';

const SignupFlow = () => {
  const [step, setStep] = useState('signup');
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { saveTokens, authToken } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('inviteToken');
  const googleCode = queryParams.get('code');

  useEffect(() => {
    const handleGoogle = async () => {
      if (!googleCode) return;

      setLoading(true);
      try {
        const response = await handleGoogleCallback(googleCode);
        console.log('Google signup successful:', response);
        
        if (response.user) {
          setUserData(response.user);
          saveTokens(response.access_token, response.refresh_token);

          if (response.user.company_id) {
            navigate('/home');
          } else {
            setStep('companyInfo');
          }
        }
      } catch (error) {
        console.error('Error during Google signup:', error);
        alert('Failed to sign up with Google');
        navigate('/signup');
      } finally {
        setLoading(false);
      }
    };

    handleGoogle();
  }, [googleCode, navigate, saveTokens]);

  const handleSignupSubmit = async (data) => {
    setLoading(true);
    try {
      let response;

      if (inviteToken) {
        response = await signupUserWithInviteToken(data, inviteToken);
      } else {
        response = await signupUser(data);
      }

      console.log('Signup successful:', response);
      setUserData(response.user);
      saveTokens(response.access_token, response.refresh_token);

      if (inviteToken) {
        navigate('/home');
      } else {
        setStep('companyInfo');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('Failed to sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyInfoSubmit = async (companyData) => {
    setLoading(true);
    try {
      const response = await companyCreate(companyData, userData.id, authToken);
      console.log('Company info submitted successfully:', response);
      
      saveTokens(response.access_token, response.refresh_token);
      navigate('/home');
    } catch (error) {
      console.error('Error during company info submission:', error);
      alert('Failed to submit company information');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Or use a proper loading spinner component
  }

  return (
    <div style={{ width: '100%' }}>
      {step === 'signup' && (
        <SignupComponent onSubmit={handleSignupSubmit} inviteToken={inviteToken} />
      )}
      {!inviteToken && step === 'companyInfo' && (
        <CompanyInfoPage onSubmit={handleCompanyInfoSubmit} />
      )}
    </div>
  );
};

export default SignupFlow;