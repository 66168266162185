import React, { useState, useEffect } from 'react';
import { Search, MoreHorizontal } from 'lucide-react';
import { fetchCompanyUsers } from '../../services/company';
import { deleteUser } from '../../services/user'; // Import deleteUser service
import { useAuth } from '../../hooks/useAuth'; 
import { Navigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext'; // Import the user context
import './AdminCompanyManagement.css';

const AdminCompanyManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null); // Track which user menu is open
  const { user, userLoading } = useUser(); // Get the user and loading state from context


  const { authToken } = useAuth(); 

  const limit = 10;

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        const fetchedUsers = await fetchCompanyUsers(authToken, skip, limit); 

        if (fetchedUsers.length < limit) {
          setHasMore(false); 
        }

        const uniqueUsers = [...new Map([...users, ...fetchedUsers].map(user => [user.id, user])).values()];
        setUsers(uniqueUsers); 
      } catch (err) {
        setError('Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      loadUsers();
    }
  }, [skip, authToken]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50 && hasMore && !loading) {
        setSkip((prevSkip) => prevSkip + limit);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading]);

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId, authToken);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId)); // Remove user from UI after successful deletion
    } catch (err) {
      console.error('Failed to delete user', err);
    }
  };

  const filteredUsers = users.filter((user) => {
    const lowerSearchQuery = searchQuery.toLowerCase();
    return user.username?.toLowerCase().includes(lowerSearchQuery) || '';
  });

  if (!user || !user.is_admin) {
    return <Navigate to="/unauthorized" replace />; // Redirect if not admin
  }

  return (
    <div className="admin-company-management">
      {/* Header Section */}
      <div className="admin-company-management__header">
        <div className="admin-company-management__tabs">
          <button className="admin-company-management__tab active">Members · {users.length}</button>
        </div>
        <div className="admin-company-management__actions">
          <div className="admin-company-management__search-container">
            <input
              type="text"
              placeholder="Search by username"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-company-management__search-input"
            />
            <Search className="admin-company-management__search-icon" />
          </div>
        </div>
      </div>

      {/* User List */}
      <div className="admin-company-management__user-list">
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => (
            <div key={user.id} className="admin-company-management__user-item">
              <div className="admin-company-management__avatar">
                {user.username?.charAt(0).toUpperCase()} {/* Avatar with the first letter */}
              </div>
              <div className="admin-company-management__user-info">
                <div className="admin-company-management__name">
                  {user.username || 'Unknown Username'} <span className="admin-company-management__role">({user.isAdmin ? 'Admin' : 'Member'})</span>
                </div>
                <div className="admin-company-management__email">{user.email || 'No Email'}</div>
              </div>

              {/* Three-dot Menu for User Actions */}
              <div className="admin-company-management__user-actions">
                <button
                  className="admin-company-management__menu-btn"
                  onClick={() => setMenuOpen(menuOpen === user.id ? null : user.id)}
                >
                  <MoreHorizontal />
                </button>
                {menuOpen === user.id && (
                  <div className="admin-company-management__menu">
                    <button
                      className="admin-company-management__menu-item"
                      onClick={() => handleDeleteUser(user.id)}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>No users found</div>
        )}
      </div>

      {loading && <div>Loading more users...</div>}
      {error && <div className="admin-company-management__error">{error}</div>}
    </div>
  );
};

export default AdminCompanyManagement;
