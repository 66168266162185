// TopBar.jsx
import React, { useState } from 'react';
import './TopBar.css';
import { FaSearch, FaUserCircle, FaPaperPlane } from 'react-icons/fa';
import { MdMic } from 'react-icons/md';
import { useAuth } from '../../hooks/useAuth';
import AddMeetingModal from './AddMeetingModal';
import { useNavigate } from 'react-router-dom';

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSignOut = () => {
    logout();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && searchText.trim()) {
      navigate(`/insights-search?query=${encodeURIComponent(searchText.trim())}`);
    }
  };

  const handleSearchIconClick = () => {
    if (searchText.trim()) {
      navigate(`/insights-search?query=${encodeURIComponent(searchText.trim())}`);
    }
  };

  const handleSendClick = () => {
    if (searchText.trim()) {
      navigate(`/insights-search?query=${encodeURIComponent(searchText.trim())}`);
    }
  };

  return (
    <div className="top-bar">
      <div className="top-bar__search">
        <div className="top-bar__search-icon-wrapper" onClick={handleSearchIconClick}>
          <FaSearch className="top-bar__search-icon" />
        </div>
        <input
          className="top-bar__search-input"
          type="text"
          placeholder="Search or ask anything across your meetings..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div className="top-bar__send-icon-wrapper" onClick={handleSendClick}>
          <FaPaperPlane className="top-bar__send-icon" />
        </div>
      </div>
      <div className="top-bar__actions">
        <button className="top-bar__invite-button" onClick={() => setIsModalOpen(true)}>
          <MdMic className="top-bar__invite-button-icon" /> Add to Live Meeting
        </button>
        <div className="top-bar__profile" onClick={toggleMenu}>
          <FaUserCircle size={40} />
          {menuOpen && (
            <div className="top-bar__profile-menu">
              <button onClick={handleSignOut} className="top-bar__menu-item">
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
      
      <AddMeetingModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default TopBar;