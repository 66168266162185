// src/services/meeting.js

const baseUrl = process.env.REACT_APP_DATA_BACKEND_BASE_URL;

export const fetchQueryResults = async (query, authToken) => {
    try {
        const response = await fetch(`${baseUrl}/search_meetings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ query }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching query results:', error);
        throw error; // Re-throw to handle it in the component
    }
};