import React, { useState, useEffect } from 'react';
import './InviteCoworkerModal.css';
import { inviteUserToOrganization, fetchPendingInvites } from '../../services/team';  // Import the services
import { useAuth } from '../../hooks/useAuth';

const InviteCoworkerModal = ({ onClose }) => {
  const [email, setEmail] = useState(''); // State to track the input email
  const [pendingInvites, setPendingInvites] = useState([]);  // State for pending invites
  const [loadingInvites, setLoadingInvites] = useState(true); // Loading state for pending invites
  const [error, setError] = useState(null); // State for error handling
  const [isLoading, setIsLoading] = useState(false); // Loading state for sending invites
  const { authToken } = useAuth(); // Get auth token from the authentication context

  // Fetch pending invites when the modal opens
  useEffect(() => {
    const fetchInvites = async () => {
      try {
        setLoadingInvites(true); // Start loading
        const invites = await fetchPendingInvites(authToken);  // Fetch pending invites
        setPendingInvites(invites);  // Set the fetched invites to state
      } catch (error) {
        setError('Failed to fetch pending invites');
      } finally {
        setLoadingInvites(false);  // Stop loading
      }
    };

    fetchInvites();  // Trigger the fetch when modal opens
  }, [authToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state

    try {
      await inviteUserToOrganization(email, authToken); // Make the API request to invite
      setEmail('');  // Clear the input after success
      const invites = await fetchPendingInvites(authToken);  // Re-fetch invites after a successful invitation
      setPendingInvites(invites); // Update the pending invites list
    } catch (error) {
      setError('Failed to send invite. Please try again.'); // Handle errors
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  const getInitialLetter = (email) => email.charAt(0).toUpperCase(); // Function to get the first letter

  return (
    <div className="invite-coworker-modal" role="dialog" aria-modal="true">
      <div className="invite-coworker-modal__overlay" onClick={onClose}></div>
      <div className="invite-coworker-modal__container">
        <button className="invite-coworker-modal__close-btn" onClick={onClose}>
          &times;
        </button>
        <h3 className="invite-coworker-modal__title">Invite a Coworker</h3>
        
        {/* Invitation Form */}
        <form onSubmit={handleSubmit} className="invite-coworker-modal__form">
          <div className="invite-coworker-modal__form-group">
            <input
              type="email"
              placeholder="Enter coworker's email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="invite-coworker-modal__input"
              required
            />
          </div>

          {error && <div className="invite-coworker-modal__error">{error}</div>}

          <button
            type="submit"
            className="invite-coworker-modal__submit-btn"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send Invite'}
          </button>
        </form>

        {/* Display Pending Invites */}
        <h4 className="invite-coworker-modal__subtitle">Pending Invitations</h4>
        {loadingInvites ? (
          <p>Loading pending invites...</p>
        ) : pendingInvites.length > 0 ? (
          <ul className="invite-coworker-modal__pending-list">
            {pendingInvites.map((invite) => (
              <li key={invite.id} className="invite-coworker-modal__pending-item">
                <div className="invite-coworker-modal__avatar">
                  {getInitialLetter(invite.email)}  {/* Avatar with first letter */}
                </div>
                <div className="invite-coworker-modal__pending-info">
                  <p className="invite-coworker-modal__pending-email">
                    {invite.email}
                  </p>
                  <span className="invite-coworker-modal__pending-status">Pending</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pending invitations</p>
        )}
      </div>
    </div>
  );
};

export default InviteCoworkerModal;
