import React from "react";
import "./LandingPage.css";
import '../../fonts.css';
import ChatWidget from './ChatWidget/ChatWidget';
import AnimatedBackground from './AnimatedBackground';
import DataSourcesAnimation from './DataSourcesAnimation/DataSourcesAnimation'

const LandingPage = () => {
  return (
    <div className="landing-page">
      <AnimatedBackground />
      <header className="header">
        <div className="logo-landing">Inveris</div>
        <nav className="nav-menu">
          <a href="/product" className="nav-item dropdown">Product</a>
          <a href="/use-case" className="nav-item dropdown">Use Case</a>
          <a href="/wall-of-love" className="nav-item">Wall of Love</a>
          <a href="/resources" className="nav-item dropdown">Resources</a>
          <a href="/pricing" className="nav-item">Pricing</a>
        </nav>
        <div className="auth-buttons">
          <a href="/login" className="login-button">Login</a>
          <a href="/signup" className="free-trial-button">Free Trial</a>
        </div>
      </header>
      
      {/* First Section */}
      <div className="landing-container"> 
        <div className="content">
          <div className="text-content">
            <h1>Your Company's Living Memory</h1>
            <p>
              Tired of sifting through endless meeting notes and documents? <br />
              <span className="highlight">Inveris</span> has the solution.
            </p>
            <p>
              Get detailed summaries of meetings, instantly search across all your company knowledge, and make informed decisions faster.
            </p>
          </div>
          <div className="widget-container">
            <ChatWidget />
          </div>
        </div>
      </div>

      {/* Second Section with DataSourcesAnimation */}
      <section className="data-sources-section">
        <div className="section-content">
          <h2>Seamlessly Integrate Your Tools</h2>
          <p>Connect your existing workflow tools and let AI do the heavy lifting</p>
        </div>
        <DataSourcesAnimation />
      </section>
    </div>
  );
};

export default LandingPage;