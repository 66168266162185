import React from 'react';
import AppRoutes from '../../routes/AppRoute';
import TopBar from '../TopBar/TopBar';
import './MainContent.css';

const MainContent = () => {
  return (
    <div className="main-content">
      <TopBar />
      <div className="content-wrapper">
        <AppRoutes />
      </div>
    </div>
  );
}

export default MainContent;
