import React, { useState, useEffect, useRef } from 'react';
import './Upload.css';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../context/UserContext';
import TeamSelector from '../SharedComponents/TeamSelector/TeamSelector';

const baseUrl = process.env.REACT_APP_DATA_BACKEND_BASE_URL;

const Upload = () => {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [selectedTeams, setSelectedTeams] = useState([]);
    const fileInputRef = useRef(null);
    const wsRef = useRef(null);
    const { authToken } = useAuth();
    const { user, loading } = useUser();

    useEffect(() => {
        if (user?.id && !loading) {
            const wsUrl = baseUrl.replace('https://', '');
            const ws = new WebSocket(`wss://${wsUrl}/ws/${user.id}`);
            wsRef.current = ws;

            ws.onopen = () => console.log(`WebSocket connection opened for user ${user.id}`);

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.progress !== undefined) {
                    setUploadProgress(message.progress);
                }
            };

            ws.onclose = () => console.log(`WebSocket connection closed for user ${user.id}`);

            ws.onerror = (error) => console.error(`WebSocket error for user ${user.id}:`, error);

            return () => {
                if (wsRef.current) {
                    wsRef.current.close();
                }
            };
        }
    }, [user?.id, loading]);

    const handleTeamsChange = (newSelectedTeams) => {
        setUploadStatus('');
        setFile(null);
        setSelectedTeams(newSelectedTeams);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            if (selectedTeams.length > 0) {
                handleUpload(selectedFile);
            } else {
                setUploadStatus('Please select at least one team before uploading.');
            }
        }
    };

    const handleUpload = async (selectedFile) => {
        if (!selectedFile || !user?.id || loading) return;
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }

        setUploadProgress(0);
        setUploadStatus('');

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('client_id', user.id);
        formData.append('teams', JSON.stringify(selectedTeams));

        try {
            const response = await fetch(`${baseUrl}/upload_file/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const result = await response.json();
            setUploadStatus(result.message);

            if (wsRef.current) {
                wsRef.current.close();
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Error uploading file.');
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            handleUpload(droppedFile);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClick = () => {
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }
        fileInputRef.current?.click();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="upload-container">
            <div className="upload">
                <h1 className="upload__title">
                    Upload an audio or video file to generate a transcript
                </h1>
                <p className="upload__subtitle">
                    Depending on the size of the audio file, it will be processed & transcribed in 10 - 15 mins
                </p>

                <div className="upload__teams-section">
                    <p className="upload__teams-title">
                        Select the teams to share this meeting's data with:
                    </p>
                    <TeamSelector 
                        selectedTeams={selectedTeams}
                        onTeamsChange={handleTeamsChange}
                    />
                </div>

                <div 
                    className={`upload__dropzone ${selectedTeams.length === 0 ? 'upload__dropzone--disabled' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={handleClick}
                >
                    <div className="upload__icon">↑</div>
                    <p className="upload__dropzone-text">
                        Drag and drop MP3, M4A, WAV or MP4 file here, or select files to upload.
                    </p>
                    <input 
                        type="file" 
                        ref={fileInputRef}
                        onChange={handleFileChange} 
                        className="upload__file-input" 
                        accept=".mp3,.m4a,.wav,.mp4"
                        disabled={selectedTeams.length === 0}
                    />
                </div>

                {uploadProgress > 0 && (
                    <div className="upload__progress">
                        <div 
                            className="upload__progress-bar" 
                            style={{ width: `${uploadProgress}%` }}
                        />
                        <span className="upload__progress-text">
                            {uploadProgress}%
                        </span>
                    </div>
                )}

                {uploadStatus && (
                    <div className={`upload__status ${
                        uploadStatus.includes('Error') ? 'upload__status--error' : ''
                    }`}>
                        {uploadStatus}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Upload;