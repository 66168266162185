import React, { useEffect, useState } from 'react';
import { Search } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { fetchUserTeam } from '../../services/team';
import { useUser } from '../../context/UserContext';
import './TeamPage.css';

const TeamManagement = () => {
  const { authToken } = useAuth();
  const { user, loading: userLoading } = useUser();
  const [team, setTeam] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getTeamData = async () => {
      try {
        setLoading(true);
        const teamsData = await fetchUserTeam(authToken); // Fetch all teams for the user

        if (teamsData && teamsData.length > 0) {
          setTeam(teamsData[0]); // Set the first team from the array as the team
        } else {
          setTeam(null);
        }
      } catch (err) {
        setError(err.message || 'Error fetching team data');
      } finally {
        setLoading(false);
      }
    };

    // Fetch team data only if the user data has loaded and authToken is available
    if (!userLoading && authToken) {
      getTeamData();
    }
  }, [authToken, userLoading]);

  // Define filteredTeamMembers with a check to prevent errors when team or team.users is undefined
  const filteredTeamMembers = team?.users
    ? team.users.filter((member) =>
        member.username?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  if (userLoading || loading) {
    return <div className="team-page__loading">Loading team data...</div>;
  }

  if (error) {
    return <div className="team-page__error">Error: {error}</div>;
  }

  if (!team) {
    return <div className="team-page__no-team">No team found.</div>;
  }

  return (
    <div className="team-page">
      {/* Team Name */}
      <div className="team-page__team-name">
        <h1>{team.name}</h1>
      </div>

      {/* Header Section */}
      <div className="team-page__header">
        <div className="team-page__tabs">
          <button className="team-page__tab active">
            Team Members · {filteredTeamMembers.length}
          </button>
        </div>
        <div className="team-page__actions">
          <div className="team-page__search-container">
            <input
              type="text"
              placeholder="Search by username"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="team-page__search-input"
            />
            <Search className="team-page__search-icon" />
          </div>
        </div>
      </div>

      {/* Team Member List */}
      <div className="team-page__member-list">
        {filteredTeamMembers.length > 0 ? (
          filteredTeamMembers.map((member) => (
            <div key={member.id} className="team-page__member-item">
              <div className="team-page__avatar">
                {member.username?.charAt(0).toUpperCase()}
              </div>
              <div className="team-page__member-info">
                <div className="team-page__name">
                  {member.username || 'Unknown Username'}{' '}
                  <span className="team-page__role">
                    ({member.is_admin ? 'Admin' : 'Member'})
                  </span>
                </div>
                <div className="team-page__email">{member.email || 'No Email'}</div>
              </div>
            </div>
          ))
        ) : (
          <div>No team members found</div>
        )}
      </div>
    </div>
  );
};

export default TeamManagement;
