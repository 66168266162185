// src/context/AuthContext.js
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenExpiry, refreshAccessToken } from '../services/token';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('access_token') || null);
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token') || null);
  const [isTokenReady, setIsTokenReady] = useState(!!authToken);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    const storedRefreshToken = localStorage.getItem('refresh_token');

    if (storedAccessToken) {
      setAuthToken(storedAccessToken);
      setIsTokenReady(true);
    }
    if (storedRefreshToken) {
      setRefreshToken(storedRefreshToken);
    }
  }, []);

  const saveTokens = (accessToken, refreshToken) => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    setAuthToken(accessToken);
    setRefreshToken(refreshToken);
    setIsTokenReady(true);
  };

  const clearTokens = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setAuthToken(null);
    setRefreshToken(null);
    setIsTokenReady(false);
  };

  const logout = () => {
    clearTokens();
    navigate('/login');
  };

  const checkTokenExpiry = async () => {
    if (!authToken) return false;
    
    const expiryTime = getTokenExpiry(authToken); // returns timestamp of expiry
    const currentTime = Date.now();
    if (expiryTime && expiryTime < currentTime) {
      // Token has expired, attempt to refresh
      try {
        await refreshAccessToken(refreshToken, saveTokens, logout);
        return true; // Successfully refreshed
      } catch (error) {
        // Refresh failed, force logout
        logout();
        return false;
      }
    }

    // Token is still valid
    return true;
  };

  return (
    <AuthContext.Provider value={{
      authToken,
      refreshToken,
      saveTokens,
      clearTokens,
      checkTokenExpiry,
      logout,
      isTokenReady,
      isLoading
    }}>
      {children}
    </AuthContext.Provider>
  );
};
