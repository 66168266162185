import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { searchUsers } from '../../services/user';  // Import the searchUsers service
import { addUserToTeam } from '../../services/team';  // Import the addUser service
import './CreateTeamModal.css';  // Reuse the same styles as CreateTeamModal
import { useAuth } from '../../hooks/useAuth';

const AddUserToTeamModal = ({ team, onClose, onAddUser }) => {
  const [searchQuery, setSearchQuery] = useState('');  // Search input state
  const [searchResults, setSearchResults] = useState([]);  // Store search results
  const [isSearching, setIsSearching] = useState(false);  // Loading state for searching users
  const [selectedUser, setSelectedUser] = useState(null);  // Store selected user for adding
  const { authToken } = useAuth();  // Get the auth token

  // Handle debounced user search
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {  // Only search when query is 2 or more characters
        setIsSearching(true);
        try {
          const users = await searchUsers(query, authToken);  // Fetch search results from the API
          setSearchResults(users);
        } catch (error) {
          console.error('Error searching users:', error);
        } finally {
          setIsSearching(false);
        }
      } else {
        setSearchResults([]);  // Clear results if query is too short
      }
    }, 500),  // Debounce time set to 500ms
    [authToken]
  );

  // Handle search input change and trigger debounced search
  useEffect(() => {
    // Only trigger search if no user is selected
    if (!selectedUser) {
      debouncedSearch(searchQuery);  // Trigger search on query change
    } else {
      setSearchResults([]);  // Clear search results if a user is selected
    }

    return () => {
      debouncedSearch.cancel();  // Cleanup debounced function
    };
  }, [searchQuery, selectedUser, debouncedSearch]);

  // Handle selecting a user from the search results
  const handleSelectUser = (user) => {
    setSelectedUser(user);  // Store the selected user
    setSearchQuery(user.email);  // Replace the search query with the selected user's email
    setSearchResults([]);  // Clear search results after user selection
  };

  // Handle submitting the selected user to the team
  const handleAddUser = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;  // Ensure a user is selected before making the request
    try {
      const addedUser = await addUserToTeam(team.id, selectedUser.id, authToken);  // Add selected user by ID
      onAddUser(addedUser);  // Update the team in the parent state
      onClose();  // Close the modal after successfully adding the user
    } catch (error) {
      console.error('Failed to add user:', error);
    }
  };

  return (
    <div className="create-team-modal" role="dialog" aria-modal="true">
      <div className="create-team-modal__overlay" onClick={onClose}></div>
      <div className="create-team-modal__container">
        <button className="create-team-modal__close-btn" onClick={onClose}>
          &times;
        </button>
        <h3 className="create-team-modal__title">Add User to {team.name}</h3>
        <form className="create-team-modal__form" onSubmit={handleAddUser}>
          {/* Search for users */}
          <div className="create-team-modal__form-group create-team-modal__form-group--member">
            <input
              type="text"
              placeholder="Search for users by email or name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="create-team-modal__input"
              disabled={!!selectedUser}  // Disable input if a user is selected
            />
            {isSearching && <div>Searching...</div>}
            {searchResults.length > 0 && (
              <div className="create-team-modal__search-results">
                {searchResults.map((user) => (
                  <div
                    key={user.id}
                    className="create-team-modal__search-result-item"
                    onClick={() => handleSelectUser(user)}  // Select user on click
                  >
                    {user.username} ({user.email})
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Replace "Close" with "Add" when a user is selected */}
          {selectedUser ? (
            <button type="submit" className="create-team-modal__submit-btn">
              Add User
            </button>
          ) : (
            <button type="button" className="create-team-modal__submit-btn" onClick={onClose}>
              Close
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddUserToTeamModal;
