// src/services/signup.js

const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const signupUser = async (formData) => {
    const { email, password, fullName, is_active = true, is_admin = true } = formData;
    //log the body
    console.log(JSON.stringify({
        username: fullName, // Use fullName as the username
        email,
        password,
        is_active,
        is_admin,
    }));

    try {
        const response = await fetch(`${baseUrl}/auth/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: fullName, // Use fullName as the username
                email,
                password,
                is_active,
                is_admin,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create account');
        }

        return await response.json();
    } catch (error) {
        console.error('Error during signup:', error);
        throw error;
    }
};

export const signupUserWithInviteToken = async (formData, inviteToken) => {
    const { email, password, fullName } = formData;
  
    try {
      const response = await fetch(
        `${baseUrl}/auth/register-with-invitation?token=${encodeURIComponent(inviteToken)}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: fullName,
            email,
            password,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to create account with invite token');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error during signup with invite token:', error);
      throw error;
    }
  };
  