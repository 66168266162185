import React, { useState } from 'react';
import { addMeetingUrl } from '../../services/meeting';
import { useAuth } from '../../hooks/useAuth';
import TeamSelector from '../SharedComponents/TeamSelector/TeamSelector';

const AddMeetingModal = ({ isOpen, onClose }) => {
  const [meetingUrl, setMeetingUrl] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { authToken } = useAuth();

  const handleTeamsChange = (newSelectedTeams) => {
    setSelectedTeams(newSelectedTeams);
    setError('');
  };

  const handleSubmit = async () => {
    if (!meetingUrl) {
      setError("Please enter a meeting URL");
      return;
    }

    if (selectedTeams.length === 0) {
      setError("Please select at least one team");
      return;
    }

    setIsLoading(true);
    try {
      await addMeetingUrl(meetingUrl, selectedTeams, authToken);
      alert("Meeting URL added successfully");
      onClose();
      // Reset form
      setMeetingUrl('');
      setSelectedTeams([]);
      setError('');
    } catch (error) {
      setError(error.message || "Failed to add meeting URL");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setMeetingUrl('');
    setSelectedTeams([]);
    setError('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="ant-dialog-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Add to Live Meeting</h2>
        </div>
        <div className="modal-body">
          <div className="modal-input-group">
            <label className="modal-label">Meeting URL</label>
            <input
              type="text"
              placeholder="Enter meeting URL"
              value={meetingUrl}
              onChange={(e) => setMeetingUrl(e.target.value)}
              className="modal-input"
            />
          </div>
          
          <div className="modal-input-teams">
            <label className="modal-label">Share with teams</label>
            <TeamSelector 
              selectedTeams={selectedTeams}
              onTeamsChange={handleTeamsChange}
            />
          </div>

          {error && (
            <div className="modal-error">
              {error}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button 
            className="modal-button modal-button-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button 
            className="modal-button modal-button-primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add Meeting"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMeetingModal;