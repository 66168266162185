import React from 'react';
import './Sidebar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, Search, Upload, Users, Factory, Hourglass } from 'lucide-react';
import { useUser } from '../../context/UserContext';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <aside className="sidebar">
      <div className="logo">
        <h1 className="logo-text">Inveris</h1>
      </div>
      <nav className="nav">
        <button
          className={`nav-button ${isActive('/home')}`}
          onClick={() => handleNavigate('/home')}
        >
          <Home className="icon" />
          <span>Home</span>
        </button>
        <button
          className={`nav-button ${isActive('/insights-search')}`}
          onClick={() => handleNavigate('/insights-search')}
        >
          <Search className="icon" />
          <span>Search</span>
        </button>
        <button
          className={`nav-button ${isActive('/uploads')}`}
          onClick={() => handleNavigate('/uploads')}
        >
          <Upload className="icon" />
          <span>Uploads</span>
        </button>
        <button
          className={`nav-button ${isActive('/team')}`}
          onClick={() => handleNavigate('/team')}
        >
          <Users className="icon" />
          <span>Teams</span>
        </button>

        {user?.is_admin && (
          <button
            className={`nav-button ${isActive('/company')}`}
            onClick={() => handleNavigate('/company')}
          >
            <Factory className="icon" />
            <span>Company</span>
          </button>
        )}
        <button
          className={`nav-button ${isActive('/operations')}`}
          onClick={() => handleNavigate('/operations')}
        >
          <Hourglass className="icon" />
          <span>Operations</span>
        </button>
      </nav>
    </aside>
  );
}

export default Sidebar;