const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const loginUser = async (email, password) => {
  const formData = new URLSearchParams();
  formData.append('username', email); 
  formData.append('password', password);

  try {
    const response = await fetch(`${baseUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(), // Convert form data to a URL-encoded string
    });

    if (!response.ok) {
      throw new Error('Failed to login');
    }

    const data = await response.json();
    return data; // Expecting an access token in the response
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const loginWithGoogle = async () => {
  window.location.href = `${baseUrl}/auth/google/login`;
};

export const handleGoogleCallback = async (code) => {
  try {
    const response = await fetch(`${baseUrl}/auth/google/callback?code=${code}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Google authentication failed');
    }
    return await response.json();
  } catch (error) {
    console.error('Error during Google callback:', error);
    throw error;
  }
};