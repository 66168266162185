import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import AnimatedBackground from '../LandingPage/AnimatedBackground';
import './CompanyInfo.css';

const CompanyInfoPage = ({ onSubmit }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <div className="company-info">
      <AnimatedBackground />
      <header className="header">
        <a className="logo-landing" href="/">Inveris</a>
      </header>
      <main className="company-info__main">
        <div className="company-info__container">
          <h1 className="company-info__title">Company Information</h1>
          <p className="company-info__subtitle">Help us understand your company better</p>
          
          <form className="company-info__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="form-label">Company Name</label>
              <input
                type="text"
                placeholder="Enter your company name"
                className={`form-input ${errors.name ? 'error' : ''}`}
                {...register("name", { required: "Company Name is required" })}
              />
              {errors.name && <span className="error-message">{errors.name.message}</span>}
            </div>

            <div className="form-group">
              <label className="form-label">Plan Type</label>
              <select
                className={`form-input ${errors.plan_type ? 'error' : ''}`}
                {...register("plan_type", { required: "Plan Type is required" })}
              >
                <option value="">Select Plan Type</option>
                <option value="FREE">Free</option>
                <option value="PRO">Pro</option>
                <option value="ENTERPRISE">Enterprise</option>
              </select>
              {errors.plan_type && <span className="error-message">{errors.plan_type.message}</span>}
            </div>

            <button type="submit" className="submit-button">Continue</button>
          </form>
        </div>
      </main>
    </div>
  );
};

CompanyInfoPage.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CompanyInfoPage;