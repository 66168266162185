import React, { useState } from 'react';
import { updateTeamDetails } from '../../services/team';  // Import the update service
import './CreateTeamModal.css';  // Reuse the same styles as CreateTeamModal
import { useAuth } from '../../hooks/useAuth';

const EditTeamModal = ({ team, onClose, onEditTeam }) => {
  const [teamName, setTeamName] = useState(team.name);
  const [teamDescription, setTeamDescription] = useState(team.description || '');
  const { authToken } = useAuth();  // Get the auth token

  // Handle form submission for editing the team
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedTeamData = {
      id: team.id,  // Include team ID in the update
      name: teamName,
      description: teamDescription,
      company_id: team.company_id,  // Use the existing company ID from the team object
    };

    try {
      const updatedTeam = await updateTeamDetails(updatedTeamData, authToken);
      onEditTeam(updatedTeam);  // Update the team in the parent state
      onClose();  // Close the modal after editing
    } catch (error) {
      console.error('Failed to update team:', error);
    }
  };

  return (
    <div className="create-team-modal" role="dialog" aria-modal="true">
      <div className="create-team-modal__overlay" onClick={onClose}></div>
      <div className="create-team-modal__container">
        <button className="create-team-modal__close-btn" onClick={onClose}>
          &times;
        </button>
        <h3 className="create-team-modal__title">Edit Team</h3>
        <form onSubmit={handleSubmit} className="create-team-modal__form">
          <div className="create-team-modal__form-group">
            <input
              type="text"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className="create-team-modal__input"
              required
            />
          </div>
          <div className="create-team-modal__form-group">
            <input
              type="text"
              placeholder="Team Description"
              value={teamDescription}
              onChange={(e) => setTeamDescription(e.target.value)}
              className="create-team-modal__input"
            />
          </div>
          <button type="submit" className="create-team-modal__submit-btn">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditTeamModal;
