// src/components/ProtectedRoute/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Sidebar from '../Sidebar/Sidebar';
import './ProtectedRoute.css';

const ProtectedRoute = ({ redirectPath = '/login' }) => {
  const { authToken, checkTokenExpiry, isTokenReady, logout } = useAuth();
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      if (authToken) {
        const isValid = await checkTokenExpiry();
        if (!isValid) {
          // checkTokenExpiry will have called logout() if invalid, 
          // but you can handle extra logic if needed.
        }
      }
      setIsTokenChecked(true);
    };

    if (isTokenReady) {
      validateToken();
    } else {
      setIsTokenChecked(true);
    }
  }, [authToken, checkTokenExpiry, isTokenReady, logout]);

  // While checking token validity, show a loading indicator
  if (!isTokenChecked) {
    return <div>Loading protected content...</div>;
  }

  // If no token, navigate to login
  if (!authToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div className="protected-layout">
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default ProtectedRoute;
