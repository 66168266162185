import React from 'react';
import './DataSourcesAnimation.css';

// Import all SVGs
import TeamsIcon from '../../../assets/icons/teams.svg';
import SlackIcon from '../../../assets/icons/slack.svg';
import ZoomIcon from '../../../assets/icons/zoom.svg';
import MeetIcon from '../../../assets/icons/meet.svg';
import GitHubIcon from '../../../assets/icons/github.svg';
import GitLabIcon from '../../../assets/icons/gitlab.svg';
import VSCodeIcon from '../../../assets/icons/vscode.svg';

const DataSourcesAnimation = () => {
  const platforms = [
    { 
      name: 'Teams',
      Icon: TeamsIcon,
      floatDelay: '0s',
      lineDelay: '1s'
    },
    { 
      name: 'Slack',
      Icon: SlackIcon,
      floatDelay: '0.5s',
      lineDelay: '1.5s'
    },
    { 
      name: 'Zoom',
      Icon: ZoomIcon,
      floatDelay: '1s',
      lineDelay: '2s'
    },
    { 
      name: 'Meet',
      Icon: MeetIcon,
      floatDelay: '1.5s',
      lineDelay: '2.5s'
    },
    { 
      name: 'GitHub',
      Icon: GitHubIcon,
      floatDelay: '2s',
      lineDelay: '3s'
    },
    { 
      name: 'GitLab',
      Icon: GitLabIcon,
      floatDelay: '2.5s',
      lineDelay: '3.5s'
    },
    { 
      name: 'VSCode',
      Icon: VSCodeIcon,
      floatDelay: '3s',
      lineDelay: '4s'
    }
  ];

  return (
    <section className="data-sources-section">
      <div className="animation-heading">
        <h2>Seamlessly Integrate Your Tools</h2>
        <p>Connect your existing workflow tools and let AI do the heavy lifting</p>
      </div>
      
      <div className="animation-container">
        {/* Lines Layer */}
        {platforms.map((platform, index) => {
          const angle = (index * (360 / platforms.length)) * (Math.PI / 180);
          const radius = 300;
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;

          return (
            <div 
              key={`line-${platform.name}`} 
              className="absolute" 
              style={{
                left: `calc(50% + ${x}px)`,
                top: `calc(50% + ${y}px)`,
                transform: 'translate(-50%, -50%)',
                zIndex: 1
              }}
            >
              {/* Line to center */}
              <div
                className="absolute top-1/2 left-1/2"
                style={{
                  width: `${radius}px`,
                  height: '2px',
                  background: 'linear-gradient(to left, rgba(100, 255, 218, 0), rgba(100, 255, 218, 0.5))',
                  transformOrigin: '0 50%',
                  transform: `rotate(${180 + angle * (180 / Math.PI)}deg)`,
                  animation: `pulse 2s infinite ${platform.lineDelay}`
                }}
              />
            </div>
          );
        })}

        {/* Central Inveris Text */}
        <div className="inveris-logo">
          <h1>Inveris</h1>
        </div>

        {/* Icons Layer */}
        {platforms.map((platform, index) => {
          const angle = (index * (360 / platforms.length)) * (Math.PI / 180);
          const radius = 300;
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;

          return (
            <div 
              key={`icon-${platform.name}`} 
              className="absolute" 
              style={{
                left: `calc(50% + ${x}px)`,
                top: `calc(50% + ${y}px)`,
                transform: 'translate(-50%, -50%)',
                zIndex: 10
              }}
            >
              {/* Platform Icon Container */}
              <div 
                className="platform-icon-container"
                style={{
                  animation: `float 3s infinite ${platform.floatDelay} ease-in-out`
                }}
              >
                {/* White Background Circle */}
                <div className="icon-background">
                  <img 
                    src={platform.Icon}
                    alt={platform.name}
                    className="icon-svg"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default DataSourcesAnimation;