import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';  // Import debounce from lodash
import { searchUsers } from '../../services/user';  // Import the searchUsers service
import { createTeam } from '../../services/team';  // Import the createTeam service
import './CreateTeamModal.css';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../context/UserContext';  // Import the user context to get company_id

const CreateTeamModal = ({ onClose, onCreateTeam }) => {
  const [teamName, setTeamName] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');  // Search input state
  const [searchResults, setSearchResults] = useState([]);  // Store search results
  const [isSearching, setIsSearching] = useState(false);  // Loading state for searching users
  const [members, setMembers] = useState([]);  // Added members state
  const { authToken } = useAuth();  // Get the auth token from useAuth
  const { user } = useUser();  // Get the user data to retrieve company_id

  // Handle debounced user search
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length > 2) {  // Only search when query is 2 or more characters
        setIsSearching(true);
        try {
          const users = await searchUsers(query, authToken);
          setSearchResults(users);
        } catch (error) {
          console.error('Error searching users:', error);
        } finally {
          setIsSearching(false);
        }
      } else {
        setSearchResults([]);  // Clear results if query is too short
      }
    }, 500),  // Debounce time set to 500ms
    [authToken]
  );

  // Handle search input change and trigger debounced search
  useEffect(() => {
    debouncedSearch(searchQuery);  // Trigger search on query change
    return () => {
      debouncedSearch.cancel();  // Cleanup debounced function
    };
  }, [searchQuery, debouncedSearch]);

  // Handle adding a member
  const handleAddMember = (user) => {
    if (!members.some((member) => member.id === user.id)) {
      setMembers([...members, user]);
    }
    setSearchQuery('');  // Clear search after adding
    setSearchResults([]);  // Clear search results after adding
  };

  // Handle team creation submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      console.error('No user data available');
      return;
    }

    const userIds = members.map(member => member.id);  // Extract user IDs for the API request

    const newTeamData = {
      name: teamName,
      description: teamDescription,
      company_id: user.company_id,  // Get company_id from user data
      users: userIds,
    };

    try {
      const newTeam = await createTeam(newTeamData, authToken);  // Call the create team API
      onCreateTeam(newTeam);  // Add the newly created team to the list
      onClose();  // Close the modal after creating the team
    } catch (error) {
      console.error('Failed to create team:', error);
    }
  };

  return (
    <div className="create-team-modal" role="dialog" aria-modal="true">
      <div className="create-team-modal__overlay" onClick={onClose}></div>
      <div className="create-team-modal__container">
        <button className="create-team-modal__close-btn" onClick={onClose}>
          &times;
        </button>
        <h3 className="create-team-modal__title">Create New Team</h3>
        <form onSubmit={handleSubmit} className="create-team-modal__form">
          <div className="create-team-modal__form-group">
            <input
              type="text"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className="create-team-modal__input"
              required
            />
          </div>
          <div className="create-team-modal__form-group">
            <input
              type="text"
              placeholder="Team Description (Optional)"
              value={teamDescription}
              onChange={(e) => setTeamDescription(e.target.value)}
              className="create-team-modal__input"
            />
          </div>
          <div className="create-team-modal__form-group create-team-modal__form-group--member">
            <input
              type="text"
              placeholder="Search for members by email or name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="create-team-modal__input"
            />
            {isSearching && <div>Searching...</div>}
            {searchResults.length > 0 && (
              <div className="create-team-modal__search-results">
                {searchResults.map((user) => (
                  <div
                    key={user.id}
                    className="create-team-modal__search-result-item"
                    onClick={() => handleAddMember(user)}
                  >
                    {user.username} ({user.email})
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Preview of added members */}
          {members.length > 0 && (
            <div className="create-team-modal__members-preview">
              <h4 className="create-team-modal__members-title">Added Members:</h4>
              <div className="create-team-modal__members-list">
                {members.map((member, index) => (
                  <div key={index} className="create-team-modal__member">
                    <div className="create-team-modal__member-avatar">
                      {member.username.charAt(0).toUpperCase()}
                    </div>
                    <p className="create-team-modal__member-email">{member.email}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <button type="submit" className="create-team-modal__submit-btn">
            Create Team
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateTeamModal;
