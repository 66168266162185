import React, { useEffect, useState } from 'react';
import { fetchMeetingOperations } from '../../services/meeting';
import { format, fromUnixTime } from 'date-fns';
import { Loader2, Calendar, Clock, AlertCircle, CheckCircle, RefreshCw, Info } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import './MeetingOperations.css';

const statusIcons = {
  'uploaded': <Clock className="meeting-operations__status-icon meeting-operations__status-icon--uploaded" />,
  'processing': <RefreshCw className="meeting-operations__status-icon meeting-operations__status-icon--processing" />,
  'completed': <CheckCircle className="meeting-operations__status-icon meeting-operations__status-icon--success" />,
  'failed': <AlertCircle className="meeting-operations__status-icon meeting-operations__status-icon--error" />,
  'vectors_stored': <CheckCircle className="meeting-operations__status-icon meeting-operations__status-icon--success" />
};

const getStatusDisplay = (status) => {
  if (status === 'done') {
    return {
      message: 'Successfully processed',
      showInfo: false,
      className: 'meeting-operations__status--success'
    };
  } else if (status === 'failed') {
    return {
      message: 'Failed to process',
      showInfo: false,
      className: 'meeting-operations__status--error'
    };
  }
  return {
    message: 'Processing',
    showInfo: true,
    info: 'This may take 10-15 minutes to complete',
    className: 'meeting-operations__status--processing'
  };
};

export default function MeetingOperations() {
  const [operations, setOperations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken } = useAuth();

  useEffect(() => {
    fetchOperations();
  }, [authToken]);

  const fetchOperations = async () => {
    try {
      setLoading(true);
      const response = await fetchMeetingOperations(authToken);
      
      const grouped = response.operations.reduce((acc, op) => {
        const dateObj = fromUnixTime(op.timestamp);
        const date = format(dateObj, 'yyyy-MM-dd');
        
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push({
          ...op,
          timestamp: dateObj
        });
        return acc;
      }, {});

      const sortedGrouped = Object.entries(grouped)
        .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
        .map(([date, ops]) => ({
          date,
          operations: ops.sort((a, b) => b.timestamp - a.timestamp)
        }));

      setOperations(sortedGrouped);
      setError(null);
    } catch (err) {
      console.error('Error in fetchOperations:', err);
      setError("Failed to fetch meeting operations");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="meeting-operations__loader">
        <Loader2 className="meeting-operations__loader-icon" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="meeting-operations__error">
        {error}
      </div>
    );
  }

  return (
    <div className="meeting-operations">
      <div className="meeting-operations__header">
        <h2 className="meeting-operations__title">Meeting Operations</h2>
        <button onClick={fetchOperations} className="meeting-operations__refresh-btn">
          <RefreshCw className="meeting-operations__refresh-icon" />
          Refresh
        </button>
      </div>

      <div className="meeting-operations__content">
        {operations.map(({ date, operations }) => (
          <div key={date} className="meeting-operations__group">
            <div className="meeting-operations__group-header">
              <Calendar className="meeting-operations__calendar-icon" />
              <h3 className="meeting-operations__group-title">
                {format(new Date(date), 'EEEE, MMMM d, yyyy')}
              </h3>
            </div>

            <div className="meeting-operations__list">
              {operations.map((op) => {
                const statusInfo = getStatusDisplay(op.status);
                return (
                  <div key={op.timestamp.getTime()} className="meeting-operations__item">
                    <div className="meeting-operations__item-content">
                      <div className="meeting-operations__details">
                        <div className="meeting-operations__timestamp">
                          Meeting recorded at {format(op.timestamp, 'MMMM d, yyyy')} {format(op.timestamp, 'h:mm a')}
                        </div>
                        <div className={`meeting-operations__status-wrapper ${statusInfo.className}`}>
                          <div className="meeting-operations__status">
                            {statusIcons[op.status]}
                            <span className="meeting-operations__badge">
                              {statusInfo.message}
                            </span>
                          </div>
                          {statusInfo.showInfo && (
                            <div className="meeting-operations__info">
                              <Info className="meeting-operations__info-icon" />
                              <span className="meeting-operations__info-text">{statusInfo.info}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        {operations.length === 0 && (
          <div className="meeting-operations__empty">
            <div className="meeting-operations__empty-message">
              No meeting operations found
            </div>
          </div>
        )}
      </div>
    </div>
  );
}