import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBriefSummary } from '../../services/meeting';
import { useAuth } from '../../hooks/useAuth';
import './BriefSummary.css';

const BriefSummary = () => {
  const { id } = useParams();
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setError(new Error("Meeting ID not found in URL"));
        setIsLoading(false);
        return;
      }

      try {
        const data = await fetchBriefSummary(id, authToken);
        setSummaryData(data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (authToken) {
      fetchData();
    }
  }, [id, authToken]);

  const formatDateTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) return <div className="brief-summary__loading">Loading...</div>;
  if (error) return <div className="brief-summary__error">Error: {error.message}</div>;
  if (!summaryData) return <div className="brief-summary__error">No data found.</div>;

  return (
    <div className="brief-summary">
      <div className="brief-summary__card">
        <div className="brief-summary__metadata">
          <span>{formatDateTime(summaryData.timestamp)}</span>
        </div>

        <div className="brief-summary__section">
          <h2 className="brief-summary__section-title">Meeting Summary</h2>
          {summaryData.summary && (
            <div className="brief-summary__text">
              {summaryData.summary}
            </div>
          )}
        </div>

        {summaryData.notes?.length > 0 && (
          <div className="brief-summary__section">
            <h2 className="brief-summary__section-title">Notes</h2>
            {summaryData.notes.map((note, index) => (
              <div key={index} className="brief-summary__note">
                <div className="brief-summary__subtitle">{note[0]}</div>
                <ul className="brief-summary__list">
                  {note[1].map((point, pointIndex) => (
                    <li key={pointIndex} className="brief-summary__list-item">
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {summaryData.action_items && summaryData.action_items.length > 0 && (
          <div className="brief-summary__section">
            <h2 className="brief-summary__section-title">Action Items</h2>
            <ul className="brief-summary__list">
              {summaryData.action_items.map((item, index) => (
                <li key={index} className="brief-summary__list-item">
                  {typeof item === 'string' ? item : item.item}
                  {item.assigned_to && (
                    <span className="brief-summary__assigned"> - {item.assigned_to}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BriefSummary;