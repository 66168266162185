import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDetailedSummary } from '../../services/meeting';
import { useAuth } from '../../hooks/useAuth';
import './DetailedSummary.css';

const DetailedSummary = () => {
  const { id } = useParams();
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setError(new Error("Meeting ID not found in URL"));
        setIsLoading(false);
        return;
      }

      try {
        const data = await fetchDetailedSummary(id, authToken);
        setSummaryData(data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (authToken) {
      fetchData();
    }
  }, [id, authToken]);

  const formatDateTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) return <div className="detailed-summary__loading">Loading...</div>;
  if (error) return <div className="detailed-summary__error">Error: {error.message}</div>;
  if (!summaryData) return <div className="detailed-summary__error">No data found.</div>;

  return (
    <div className="detailed-summary">
      <div className="detailed-summary__card">
        <div className="detailed-summary__metadata">
          <span>{formatDateTime(summaryData.timestamp)}</span>
          <span>•</span>
          <span>ID: {summaryData.meetingId}</span>
        </div>

        <div className="detailed-summary__section">
          <h2 className="detailed-summary__section-title">Detailed Summary</h2>
          <div className="detailed-summary__content">
            {summaryData.topics?.map((topic, index) => (
              <div key={index} className="detailed-summary__topic">
                <h3 className="detailed-summary__subtitle">{topic}</h3>
                <div className="detailed-summary__details">
                  {summaryData.details[index]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedSummary;