// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import SignupFlow from './components/SignUpFlow/SignUpFlow';
import SignInComponent from './components/SignIn/SignIn';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import MainContent from './components/MainContent/MainContent';
import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';  // Move UserProvider here
import Unauthorized from './components/Unauthorized/Unauthorized';

function App() {
  return (
    <Router>
      <AuthProvider>
        {/* UserProvider should wrap all the protected content */}
        <UserProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<SignupFlow />} />
            <Route path="/login" element={<SignInComponent />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/auth/google/callback" element={<SignupFlow />} />

            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              {/* Catch-all route for protected content */}
              <Route path="*" element={<MainContent />} />
            </Route>
          </Routes>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
