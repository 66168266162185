import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getUserDetails } from '../services/user'; // This calls /auth/me
import { useAuth } from '../hooks/useAuth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { authToken, logout } = useAuth();
  const [user, setUser] = useState(null); // Holds user details
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [error, setError] = useState(null); // Tracks any errors that occur

  // We use a ref to track whether the user has already been fetched during this session
  const hasFetchedUser = useRef(false);

  // Reset user data on logout
  useEffect(() => {
    if (!authToken) {
      // Reset the user data when there's no token (i.e., after logout)
      setUser(null);
      hasFetchedUser.current = false;
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (authToken && !hasFetchedUser.current) { // Only fetch if token exists and we haven't fetched yet
        try {
          setLoading(true);
          setError(null); // Clear any previous errors
          console.log('Fetching user details with token:', authToken); // Log the token used to fetch user details
          const userData = await getUserDetails(authToken); // Fetch user details (/auth/me)
          setUser(userData); // Set the fetched user data
          hasFetchedUser.current = true; // Mark that we've fetched the user during this session
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          setError('Failed to fetch user details. Please try again.');
          logout(); // Log out if there's an error fetching user data (e.g., invalid token)
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      }
    };

    fetchUserData(); // Run the fetch function on mount or when authToken changes
  }, [authToken, logout]);

  return (
    <UserContext.Provider value={{ user, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to access UserContext
export const useUser = () => useContext(UserContext);
