import React, { useState, useRef, useEffect } from 'react';
import { Users, ChevronsUpDown, Check } from 'lucide-react';
import { useAuth } from '../../../hooks/useAuth';
import { fetchTeams } from '../../../services/team';
import './TeamSelector.css';

const TeamSelector = ({ selectedTeams, onTeamsChange }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const { authToken } = useAuth();

  useEffect(() => {
    const loadTeams = async () => {
      try {
        setLoading(true);
        const teamsData = await fetchTeams(authToken);
        setTeams(teamsData);
        setError(null);
      } catch (err) {
        setError('Failed to load teams. Please try again later.');
        console.error('Error loading teams:', err);
      } finally {
        setLoading(false);
      }
    };

    loadTeams();
  }, [authToken]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredTeams = teams.filter(team => 
    team.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="team-selector">
        <div className="team-selector__header">
          <Users className="team-selector__icon" />
          <p className="team-selector__label">Loading teams...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="team-selector">
        <div className="team-selector__header">
          <Users className="team-selector__icon" />
          <p className="team-selector__label">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="team-selector" ref={dropdownRef}>
      
      <div className="team-selector__container">
        <button
          className="team-selector__trigger"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          type="button"
        >
          <span className="team-selector__trigger-text">
            {selectedTeams.length === 0
              ? "Select teams..."
              : `${selectedTeams.length} team${selectedTeams.length > 1 ? 's' : ''} selected`}
          </span>
          <ChevronsUpDown className="team-selector__trigger-icon" />
        </button>

        {open && (
          <div className="team-selector__dropdown">
            <div className="team-selector__search">
              <input
                type="text"
                placeholder="Search teams..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="team-selector__search-input"
                autoComplete="off"
              />
            </div>

            <div className="team-selector__options">
              {filteredTeams.length === 0 ? (
                <div className="team-selector__empty">No team found.</div>
              ) : (
                filteredTeams.map((team) => {
                  const isSelected = selectedTeams.includes(team.id);
                  return (
                    <button
                      key={team.id}
                      className={`team-selector__option ${isSelected ? 'team-selector__option--selected' : ''}`}
                      onClick={() => {
                        if (isSelected) {
                          onTeamsChange(selectedTeams.filter(id => id !== team.id));
                        } else {
                          onTeamsChange([...selectedTeams, team.id]);
                        }
                      }}
                      type="button"
                    >
                      <Check className={`team-selector__check ${isSelected ? '' : 'team-selector__check--hidden'}`} />
                      {team.name}
                    </button>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>

      {selectedTeams.length > 0 && (
        <div className="team-selector__badges">
          {selectedTeams.map((teamId) => {
            const team = teams.find(t => t.id === teamId);
            return team ? (
              <span key={teamId} className="team-selector__badge">
                {team.name}
                <button
                  className="team-selector__badge-remove"
                  onClick={() => onTeamsChange(selectedTeams.filter(id => id !== teamId))}
                  type="button"
                >
                  ×
                </button>
              </span>
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

export default TeamSelector;