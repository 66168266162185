import React, { useState, useEffect, useRef } from 'react';
import { Search } from 'lucide-react';
import './ChatWidget.css';

const TYPING_SPEED = 50;
const QUESTION_PAUSE = 1000;
const LOADING_TIME = 2000;
const RESPONSE_TIME = 4000;
const RESTART_DELAY = 1000;

const ChatWidget = () => {
  const [displayState, setDisplayState] = useState('empty');
  const [text, setText] = useState('');
  const [currentQA, setCurrentQA] = useState(0);

  const timeoutRef = useRef(null);

  const qaPairs = [
    {
        question: "What are the highlights from today's meeting?",
        response: `The major announcement was launching our enterprise platform in Q3 2024. We're increasing the marketing budget by 25% to support this, and we'll be growing the team significantly. Sarah presented an impressive roadmap of new features that got unanimous approval.`
      },
      {
        question: "How do I request time off in the company?",
        response: `Just log into Rippling and click "Time Off" in the left sidebar. Submit your dates and it'll automatically notify your manager. Try to give at least 2 weeks notice for longer breaks. Your manager usually responds within 24 hours.`
      },
    {
      question: "How do we create a user in our codebase?",
      response: "Use the createUser() method:\n\n```\nresult = createUser(data)\n```"
    }
  ];

  useEffect(() => {
    let isCancelled = false;

    const cleanup = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    const question = qaPairs[currentQA].question;

    const typeQuestion = (index) => {
      if (isCancelled) return;

      if (index < question.length) {
        setText((prev) => prev + question[index]);
        timeoutRef.current = setTimeout(() => {
          typeQuestion(index + 1);
        }, TYPING_SPEED);
      } else {
        timeoutRef.current = setTimeout(() => {
          showLoading();
        }, QUESTION_PAUSE);
      }
    };

    const showLoading = () => {
      if (isCancelled) return;

      setDisplayState('loading');
      timeoutRef.current = setTimeout(() => {
        showResponse();
      }, LOADING_TIME);
    };

    const showResponse = () => {
      if (isCancelled) return;

      setDisplayState('response');
      timeoutRef.current = setTimeout(() => {
        nextQuestion();
      }, RESPONSE_TIME);
    };

    const nextQuestion = () => {
      if (isCancelled) return;

      setDisplayState('empty');
      setText('');
      timeoutRef.current = setTimeout(() => {
        setCurrentQA((prev) => (prev + 1) % qaPairs.length);
      }, RESTART_DELAY);
    };

    setText('');
    setDisplayState('typing');
    typeQuestion(0);

    return () => {
      isCancelled = true;
      cleanup();
    };
  }, [currentQA]);

  const renderResponse = () => {
    if (displayState !== 'response') return null;
  
    const response = qaPairs[currentQA].response;
  
    // Split response into segments of text and code blocks
    const codeBlockRegex = /```([\s\S]*?)```/g;
    let lastIndex = 0;
    const segments = [];
    let match;
  
    while ((match = codeBlockRegex.exec(response)) !== null) {
      if (match.index > lastIndex) {
        // Text before code block
        segments.push({
          type: 'text',
          content: response.slice(lastIndex, match.index),
        });
      }
      // Code block
      segments.push({
        type: 'code',
        content: match[1],
      });
      lastIndex = codeBlockRegex.lastIndex;
    }
  
    if (lastIndex < response.length) {
      // Remaining text after last code block
      segments.push({
        type: 'text',
        content: response.slice(lastIndex),
      });
    }
  
    let firstBoldApplied = false;
  
    // Render the segments
    return (
      <div className="chat-widget__response-content">
        {segments.map((segment, index) => {
          if (segment.type === 'text') {
            // Process the text content
            let content = segment.content;
  
            // For the first occurrence of "createUser()", make it bold
            const boldRegex = /(createUser\(\))/;
            const parts = content.split(boldRegex);
  
            const renderedParts = parts.map((part, idx) => {
              if (!firstBoldApplied && part === 'createUser()') {
                firstBoldApplied = true;
                return <strong key={`${index}-${idx}`}>{part}</strong>;
              } else {
                // Replace newlines with <br />
                const textWithLineBreaks = part.split('\n').reduce((acc, line, lineIdx) => {
                  if (lineIdx > 0) acc.push(<br key={`${index}-${idx}-br-${lineIdx}`} />);
                  acc.push(line);
                  return acc;
                }, []);
                return textWithLineBreaks;
              }
            });
  
            return (
              <div key={index} className="chat-widget__response-line">
                {renderedParts}
              </div>
            );
          } else if (segment.type === 'code') {
            // Render code block using <code> instead of <pre>
            return (
              <div key={index} className="chat-widget__response-code">
                <code>{segment.content}</code>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };
  

  return (
    <div className="chat-widget">
      <div className="chat-widget__overlay" />
      <div className="chat-widget__content">
        <div className="chat-widget__header">
          <div className="chat-widget__title">Inveris</div>
          <div className="chat-widget__status">
            <div className="chat-widget__status-dot" />
            <span className="chat-widget__status-text">Online</span>
          </div>
        </div>

        <div className="chat-widget__search">
          <Search className="chat-widget__search-icon" />
          <input
            type="text"
            value={text}
            readOnly
            className="chat-widget__search-input"
            placeholder="Ask any question..."
          />
          {displayState === 'typing' && <div className="chat-widget__cursor" />}
        </div>

        {displayState === 'loading' && (
          <div className="chat-widget__loader">
            <div className="chat-widget__loader-rings">
              <div className="chat-widget__loader-ring chat-widget__loader-ring--outer" />
              <div className="chat-widget__loader-ring chat-widget__loader-ring--inner" />
            </div>
          </div>
        )}

        {displayState === 'response' && (
          <div className="chat-widget__response">
            {renderResponse()}
          </div>
        )}

        <div className="chat-widget__footer">
          <span className="chat-widget__footer-text">Response powered by AI</span>
          <span className="chat-widget__footer-status">
            {displayState === 'typing' && 'Typing...'}
            {displayState === 'loading' && 'Processing...'}
            {displayState === 'response' && 'Completed'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
