// src/services/company.js

const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const companyCreate = async (companyData, userId, token) => {
  try {
    const response = await fetch(`${baseUrl}/companies/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`  // Include the access token in the Authorization header
      },
      body: JSON.stringify({
        name: companyData.name,
        plan_type: companyData.plan_type,
        user_id: userId,  // Optionally include user ID if needed by your backend
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to create company');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during company creation:', error);
    throw error;
  }
};

// Fetch company users with pagination
export const fetchCompanyUsers = async (token, skip = 0, limit = 10) => {
  try {
    const response = await fetch(`${baseUrl}/companies/users?skip=${skip}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch company users');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetching company users:', error);
    throw error;
  }
};
