// src/services/token.js
const BASE_URL = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const getTokenExpiry = (token) => {
  if (!token) return null;
  const decoded = JSON.parse(atob(token.split('.')[1]));
  return decoded.exp * 1000; // Convert expiry time to milliseconds
};

export const refreshAccessToken = async (refreshToken, saveTokens, logout) => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch(`${BASE_URL}/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: refreshToken // Pass the refresh token in JSON body
        }),
      });

      if (response.ok) {
        const { access_token, refresh_token } = await response.json();
        console.log('Token refreshed successfully');
        saveTokens(access_token, refresh_token);
        return;
      } else {
        retries -= 1;
        console.log(`Token refresh failed, ${retries} retries left`);
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      retries -= 1;
    }
  }
  console.log('Token refresh failed, logging out');
  logout();  // If all retries fail, log out the user
};
  
